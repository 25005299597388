import {initializeApp} from 'firebase/app';
import {getAuth, setPersistence, browserLocalPersistence, onAuthStateChanged} from 'firebase/auth';
import * as firebaseAuth from 'firebase/auth';
import window from 'global/window';
import AltoIcon from './alto-icon';
import {MAP_URI} from '../../constants/default-settings';
import {Provider} from '@kepler.gl/cloud-providers';
import * as firebaseui from 'firebaseui'
import axios from 'axios';

const NAME = 'alto';
const DISPLAY_NAME = 'Alto';
const PRIVATE_STORAGE_ENABLED = true;
const SHARING_ENABLED = true;

export default class AltoProvider extends Provider {
    constructor(firebaseConfig, altoUrl) {
        super({
            name: NAME,
            displayName: DISPLAY_NAME,
            icon: AltoIcon,
        });
        this._folderLink = "";
        // Initialize firebase
        this._url = altoUrl;
        this._app = initializeApp(firebaseConfig);
        const auth = getAuth(this._app);
        onAuthStateChanged(auth, (user) => {
            this._currentUser = user;
        })
        // this.logout();
    }

    login(onCloudLoginSuccess) {
        const auth = getAuth(this._app);
        const ui = new firebaseui.auth.AuthUI(auth);
        return setPersistence(auth, browserLocalPersistence)
            .then(() => {
                ui.start('#firebaseui-auth-container', {
                    signInFlow: 'popup',
                    signInOptions: [
                        // firebaseAuth.EmailAuthProvider.PROVIDER_ID,
                        firebaseAuth.GoogleAuthProvider.PROVIDER_ID,
                    ],
                    callbacks: {
                        signInSuccessWithAuthResult(authResult, redirectUrl) {
                            if (typeof onCloudLoginSuccess === 'function') {
                                onCloudLoginSuccess();
                            }
                        },
                        signInFailure(error) {
                            // todo
                        }
                    }
                });
            });
    }

    _waitXTime(ms = 0) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    }

    getAccessToken() {
        if (this._currentUser && this._currentUser.accessToken) {
            return this._currentUser.accessToken;
        }
        return null;
    }

    _loadAccessToken() {
        if (this._currentUser && this._currentUser.accessToken) {
            return Promise.resolve(this._currentUser.accessToken);
        } else {
            return this._waitXTime(1000)
                .then(() => {
                    if (this._currentUser) {
                        return this._currentUser.accessToken;
                    } else {
                        return null;
                    }
                });
        }
    }

    getUserName() {
        if (this._currentUser && this._currentUser.displayName) {
            return this._currentUser.displayName;
        }
        return null;
    }

    logout(onCloudLogoutSuccess) {
        const auth = getAuth(this._app);
        return auth.signOut()
            .then(() => {
                if (typeof onCloudLogoutSuccess === 'function') {
                    onCloudLogoutSuccess();
                }
            });
    }

    hasPrivateStorage() {
        return PRIVATE_STORAGE_ENABLED;
    }

    hasSharingUrl() {
        return SHARING_ENABLED;
    }

    _downloadPublicMap(loadParams) {
        return axios({
            url: `/public/${loadParams.uuid}/map`,
            baseURL: this._url,
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(result => {
                const response = {
                    map: result.data,
                    format: 'keplergl'
                };

                this._loadParam = loadParams;
                return response;
            })
            .catch(this._handleError);
    }

    _downloadPrivateMap(loadParams) {
        return this._loadAccessToken()
            .then(token => {
                if (!token) {
                    return this.login(() => this.downloadMap(loadParams));
                }
                return axios({
                    baseURL: this._url,
                    url: `/${loadParams.uuid}/map`,
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(result => {
                        const response = {
                            map: result.data,
                            format: 'keplergl'
                        };

                        this._loadParam = loadParams;
                        return response;
                    })
            })
            .catch(this._handleError);
    }

    downloadMap(loadParams) {
        if (loadParams.isPublic === true) {
            return this._downloadPublicMap(loadParams);
        }
        return this._downloadPrivateMap(loadParams);
    }

    listMaps() {
        return this._loadAccessToken()
            .then(token => {
                if (!token) {
                    return this.login(() => this.listMaps());
                }
                const limit = 50;
                const publicQuery = {
                    key: 'isPrivate',
                    value: false
                };
                const privateQuery = {
                    key: ['isPrivate', 'createdBy'],
                    value: [true, this._currentUser.uid]
                };
                return Promise
                    .all([
                        this._loadMaps([], 0, limit, token, publicQuery),
                        this._loadMaps([], 0, limit, token, privateQuery)
                    ])
                    .then(results => {
                        const list = results[0].concat(results[1]);
                        return list.sort((a, b) => {
                            return b.lastModification - a.lastModification;
                        });
                    })
            })
            .catch(this._handleError);
    }

    _loadMaps(list = [], offset = 0, limit = 25, token = "", params = {}) {
        return axios({
            baseURL: this._url,
            url: '/search',
            params: {
                offset,
                limit: limit + 1,
                ...params
            },
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
            .then((results) => {
                const dataList = results.data.map(d => {
                    return {
                        title: d.title,
                        description: d.description,
                        id: d.uuid,
                        lastModification: new Date(d.updatedAt).getTime(),
                        imageUrl: d.thumbnail,
                        thumbnail: d.thumbnail,
                        privateMap: d.isPrivate,
                        loadParams: {
                            uuid: d.uuid,
                            shareUrl: d.shareUrl
                        }
                    };
                });
                const hasMore = (dataList.length > limit);
                if (!hasMore) {
                    return list.concat(dataList);
                }
                return this._loadMaps(list.concat(dataList.slice(0, limit)), offset + limit, limit, token, url);
            });
    }

    _handleError(error) {
        if (error && error && error.message && Array.isArray(error.message) && error.status === 422) {
            const msg = error.message.reduce((prev, curr, index) => {
                if (index > 0) {
                    prev += '\n';
                }
                prev += `[${curr.location}] ${curr.param} ${curr.msg}`;
                return prev;
            }, '');
            throw new Error(msg);
        } else if (error && error && error.message) {
            throw new Error(error.message);
        } else {
            throw new Error(error.message);
        }
    }

    uploadMap({mapData, options = {}}) {
        return this._loadAccessToken()
            .then(token => {
                if (!token) {
                    return this.login(() => this.uploadMap({mapData, options}));
                }
                const {isPublic = true, overwrite = true} = options;
                const {map: {config, datasets, info} = {}, thumbnail} = mapData;
                const {title, description} = info;

                if (!overwrite) {
                    return this._createKeplerMap(title, description, isPublic, token)
                        .then((result) => {
                            const uuid = result.uuid;
                            this._shareUrl = uuid;
                            const patchPromise = (isPublic)
                                ? this._patchKeplerMap(uuid, this._shareUrl, token)
                                : Promise.resolve();
                            return Promise
                                .all([
                                    patchPromise,
                                    this._uploadThumbnail(uuid, thumbnail, token),
                                    this._uploadMapData(uuid, mapData.map, token),
                                ])
                                .then(() => {
                                    if (isPublic) {
                                        return {
                                            shareUrl: this.getShareUrl(true),
                                            folderLink: this._folderLink
                                        }
                                    }
                                    this._loadParam = {uuid};
                                    return this._loadParam;
                                });
                        });
                } else {
                    const uuid = info.loadParams.uuid;
                    this._shareUrl = uuid;
                    const patchPromise = (isPublic)
                        ? this._patchKeplerMap(uuid, this._shareUrl, token)
                        : Promise.resolve();
                    return Promise
                        .all([
                            patchPromise,
                            this._uploadThumbnail(uuid, thumbnail, token),
                            this._uploadMapData(uuid, mapData.map, token),
                        ])
                        .then(() => {
                            if (isPublic) {
                                return {
                                    shareUrl: this.getShareUrl(true),
                                    folderLink: this._folderLink
                                }
                            }
                            this._loadParam = {uuid};
                            return this._loadParam;
                        })
                }
            })
            .catch(this._handleError);
    }

    _patchKeplerMap(uuid = "", shareUrl = "", token = "") {
        return axios({
            baseURL: this._url,
            url: `/${uuid}`,
            method: "PATCH",
            data: {shareUrl},
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
    }

    _createKeplerMap(title = "", description = "", isPublic = true, token = "") {
        return axios({
            method: "POST",
            baseURL: this._url,
            url: '/',
            data: {
                title,
                description,
                isPrivate: !isPublic,
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
            .then(results => results.data);
    }

    _uploadThumbnail(uuid, thumbnail, token) {
        if (!thumbnail) {
            return Promise.resolve();
        }
        const formData = new FormData();
        formData.append("file", thumbnail);
        return axios({
            baseURL: this._url,
            url: `/${uuid}/thumbnail`,
            method: "POST",
            data: formData,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(results => results.data);
    }

    _uploadMapData(uuid, mapData, token) {
        if (!mapData) {
            return Promise.resolve();
        }
        return axios({
            baseURL: this._url,
            url: `/${uuid}/map`,
            method: "POST",
            data: mapData,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
            .then(results => results.data);
    }

    getManagementUrl() {
        return this._folderLink;
    }

    /**
     * Get the share url of current map, this url can be accessed by anyone
     * @param {boolean} fullUrl
     */
    getShareUrl(fullUrl = true) {
        return fullUrl
            ? `${window.location.protocol}//${window.location.host}/${MAP_URI}${this._shareUrl}`
            : `/${MAP_URI}${this._shareUrl}`;
    }

    /**
     * Get the map url of current map, this url can only be accessed by current logged in user
     * @param {boolean} fullUrl
     */
    getMapUrl(fullURL = true) {
        const {uuid, shareUrl} = this._loadParam;
        if (shareUrl) {
            const mapLink = `shared/map?mapUrl=${shareUrl}`;
            return fullURL
                ? `${window.location.protocol}//${window.location.host}/${mapLink}`
                : `/${mapLink}`;
        } else {
            const mapLink = `shared/map?mapUuid=${uuid}`;
            return fullURL
                ? `${window.location.protocol}//${window.location.host}/${mapLink}`
                : `/${mapLink}`;
        }
    }
}
